<template>
  <div class="product-badges z-10"
    :class="{ 'absolute top-[10px] left-[10px]  md:p-[10px]': absolute, 'max-w-[112px]': !absolute }">

    <!-- Online Only -->
    <div v-if="isOnlineOnly"
      class="product-badge product-badge--new uppercase mb-[10px] bg-black rounded-full text-white px-4 py-[2px] font-alt text-center"
      :class="{ 'text-sm': absolute, 'text-xs': !absolute }">
      Online Only
    </div>

    <!-- New Arrival -->
    <div v-if="isNewArrival"
      class="product-badge product-badge--sale uppercase mb-[10px] text-center bg-[#FBCFE8] text-black px-4 py-[2px] rounded-full font-alt"
      :class="{ 'text-sm': absolute, 'text-xs': !absolute }">
      New Arrival
    </div>

    <!-- Staff Pick -->
    <div v-if="isStaffPick"
      class="product-badge product-badge--sale uppercase mb-[10px] text-center bg-[#179DAB] text-black px-4 py-[2px] rounded-full text-white font-alt"
      :class="{ 'text-sm': absolute, 'text-xs': !absolute }">
      Staff Pick
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    absolute: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isOnlineOnly() {
      console.log(this.tags);
      return this.tags.includes('Online Only');
    },
    isNewArrival() {
      return this.tags.includes('New Arrival');
    },
    isStaffPick() {
      return this.tags.includes('Staff Pick');
    }
  }
}
</script>
